<template>
<div class="container-fluid">
      <ConfirmAlert ref="MyConfirmAlert" :title="AlertTitle" :message="AlertMessage" @Sure="YesIamSure($event)" :sureResult="sureResult" />
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="false"/>

            <div class="col-md-12">
                <div class="col-lg-12 col-md-12 ">
                <div class="row">
                    <div class="col-md-4">
                    <form @submit.prevent="onSearch()" class="input-group no-border">
                        <input type="text" v-model="filter.name" class="form-control" placeholder="بحث">
                        <button @click="onSearch()" type="submit" class="btn btn-white btn-round btn-just-icon">
                            <i class="material-icons">search</i>
                            <div class="ripple-container"></div>
                        </button>
                    </form>
                    </div>
                    <div class="col-md-4">
                    <PerPage id="limit" title="كل صفحة"  Mykey="limit"  :MyValue="filter.limit" @Select="HandleSelect('limit',$event)"/>
                    </div>
                    <div class="col-md-4">
                        <router-link v-if="manageService('can_add')" :to="{name: 'admin.dashboard.colors.create'}" class="btn btn-primary btn-md btn-add" > <i class="fas fa-pencil-alt"> </i>
                            اضافة لون</router-link>
                    </div>
                
                </div>
                </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title ">قائمة الالوان</h4>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                          اسم اللون بالعربي
                        </th>
                        <th>
                          اسم اللون بالانجليزي
                        </th>

                        <th v-if="manageService('can_edit') || manageService('can_delete')">
                          اجراءات
                        </th>

                      </thead>
                      <tbody>
                        <tr v-for="(color,index) in ColorsList.data" :key="index">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                            {{color.name.ar}}
                          </td>
                          <td>
                            {{color.name.en}}
                          </td>

                          <td class="text-primary" v-if="manageService('can_edit') || manageService('can_delete')">
                            <router-link v-if="manageService('can_edit')" :to="{name: 'admin.dashboard.colors.edit', params: {id: color.id}}" class="btn btn-warning btn-sm" > 
                             تعديل</router-link>
                             <a href="" v-if="manageService('can_delete')" class="btn btn-danger btn-sm" @click.prevent="ShowConfirm(color)">
                             حذف</a> 
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
             <pagination v-if="ColorsList.meta.last_page > 1" :pagination="ColorsList" :offset="5" @paginate="onPaginationChage"></pagination> 
             </div>
</div>
</template>

<script>
import store from '../../../../store/index'
import {mapState} from 'vuex';

import Pagination from '../../../../components/Pagination.vue'
import PerPage from '../../../../components/PerPage'
import ConfirmAlert from '../../../../components/ConfirmAlert'
import SuccessAlert from '../../../../components/SuccessAlert'
export default {
  data(){
   return{
   filter:{
        name:'',
        page:1,
        paginate:1,
        limit:'100',
    },  
     AlertTitle:'',
     AlertMessage:'',
     SelectedColor:null,
     sureResult:true,
   }
  },
  computed:{
    ...mapState({

        ColorsList:state=>state.admin.colors.ColorsList,
    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
    
   },
   methods:{
     HandleSelect(key,value){
         this.filter[key]=value;
         this.onPaginationChage(1);
     },
        onPaginationChage(page){
       this.filter.page=page;
         store.dispatch('admin/fetchColorsList',this.filter).then((response)=>{
         }).catch((error)=>{
         })
       },
        onSearch(){
          store.commit('admin/PleaseStartLoading');
            this.filter.page=1;
            store.dispatch('admin/fetchColorsList',this.filter).then(()=>{
              store.commit('admin/PleaseStopLoading');
            }).catch((error)=>{
              store.commit('admin/PleaseStopLoading');
            });  
        },  
        YesIamSure(value){
        if(value && this.sureResult){
            this.sureResult=false;
            this.onDelete();
        }
        },
        CancelAlert(){
        this.sureResult=false;
        },
        ShowConfirm(color){
                this.AlertTitle="معلومات",
                this.AlertMessage="هل انت متاكد من الحذف ؟";
                this.$refs.MyConfirmAlert.showModel();
                this.SelectedColor=color;
        },
         onDelete(){
           store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/deleteColor',this.SelectedColor.id).then((Response)=>{
            store.commit('admin/PleaseStopLoading');
            this.sure=false;
           this.$refs.MyConfirmAlert.hideModel();
            this.AlertTitle="معلومات",
            this.AlertMessage="تمت الحذف بنجاح";
            this.$refs.MySuccessAlert.showModel();
            store.dispatch('admin/fetchColorsList',this.filter).then(()=>{
             this.sureResult=true;
            }).catch(error=>{

            })
          }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
        });
        

    },
   },
      components:{
       Pagination,
       PerPage,
       ConfirmAlert,
       SuccessAlert
   },

  beforeRouteEnter(to,from,next){     
      store.commit('admin/PleaseStartLoading');
      store.dispatch('admin/fetchColorsList',{page:1,search:'',paginate:1,limit:100}).then(()=>{
         store.commit('admin/PleaseStopLoading');
          next();  
      }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
       next(false);
      });             

    },
}
</script>

<style>

</style>